const forms = {

    loop() {
        let $this = $(this);
        if (!$this.data('initialized')) {
            $this.data('initialized', true);
            $this.on('focus', function () {
                $this.closest('.form-control').addClass('focus');
                $this.closest('.form-control').addClass('filled');
            });
            $this.on('blur', function () {
                $this.closest('.form-control').removeClass('focus');
                if (!$this.val()) {
                    $this.closest('.form-control').removeClass('filled');
                }
            });
        }
    },

    init() {
        let $formElements = $('input, textarea');
        if ($formElements.length) $formElements.each(this.loop);
    },
}

export default forms;
