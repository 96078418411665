import Favicon from '../partials/favicon';
import ScrollGround from '../partials/scrollGround';
import Header from '../partials/header';
import Slider from '../partials/slider';
import Pinned from '../partials/pinned';
import Forms from '../partials/forms';

export default {
    init() {
        // JavaScript to be fired on all pages
        Favicon.init();
        ScrollGround.init();
        Header.init();
        Slider.init();
        Pinned.init();
        Forms.init();
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
