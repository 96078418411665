// typical import
import gsap from 'gsap';

// get other plugins:
import ScrollTrigger from 'gsap/ScrollTrigger';

// don't forget to register plugins
gsap.registerPlugin(ScrollTrigger);

const pinned = {

    wrapped(wrapper) {
        const inner = wrapper.querySelector('.js-pinned-images-inner');

        gsap.to(inner, {
            x: () => -(inner.scrollWidth - document.documentElement.clientWidth) + 'px',
            ease: 'none',
            scrollTrigger: {
                start: 'top',
                trigger: wrapper,
                pin: true,
                scrub: true,
                markers: false,
                invalidateOnRefresh: true,
                end: () => `+=${inner.offsetWidth}`,
            },
        });
    },

    init() {
        console.log('Pinned');
        const pinnedImageWrappers = document.querySelectorAll('.js-pinned-images-wrap');
        if (pinnedImageWrappers.length) {
            pinnedImageWrappers.forEach(this.wrapped);
        }
    },
}

export default pinned;
