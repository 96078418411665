const favicon = {

    colorMatch() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },

    colorScheme() {
        return (this.colorScheme) ? 'dark' : 'light';
    },

    colorChange(svg, png) {
        if (!svg && !png) return false;
        if (this.href == null) this.href = svg.href;
        let color = (this.colorScheme === 'light') ? '' : '-dark';
        let hrefSVG = this.href.substr(0, this.href.lastIndexOf('.'));
        hrefSVG = `${hrefSVG}${color}.svg`;
        svg.href = hrefSVG;
        let hrefPNG = hrefSVG.substr(0, hrefSVG.lastIndexOf('.'));
        hrefPNG = `${hrefPNG}.png`;
        png.href = hrefPNG;
    },

    init() {
        let svg = document.querySelector('.js-site-favicon[type="image/svg+xml"]');
        let png = document.querySelector('.js-site-favicon[type="image/png"]');
        this.href = null;
        this.colorChange(svg, png);
    },

}

export default favicon;
