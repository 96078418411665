const scrollGround = {

    getTargetSection(entry) {
        const index = this.sections.findIndex((section) => section == entry.target);

        if (index >= this.sections.length - 1) {
            return entry.target;
        } else {
            return this.sections[index + 1];
        }
    },

    updateLogo(target) {
        if (target.classList.contains('dark')) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    },

    shouldUpdate(entry) {
        if (this.direction === 'down' && !entry.isIntersecting) {
            return true;
        }

        if (this.direction === 'up' && entry.isIntersecting) {
            return true;
        }

        return false;
    },

    onIntersect(entries) {
        entries.forEach((entry) => {
            if (document.scrollTop > this.prevYPosition) {
                this.direction = 'down';
            } else {
                this.direction = 'up';
            }

            this.prevYPosition = document.scrollTop;

            const target = this.direction === 'down' ? this.getTargetSection(entry) : entry.target;

            if (this.shouldUpdate(entry)) {
                this.updateLogo(target);
            // 	updateMarker(target)
            }
        });

    },

    pages(page) {
        this.intObserve.observe(page);
    },

    init() {
        //let handle = document.getElementById('header-handle');
        //let scrollRoot = document.body;
        this.options = {
            //root: scrollRoot,
            rootMargin: '-50% 0% -50% 0%',
            threshold: 0,
        };
        this.prevYPosition = 0;
        this.direction = 'up';
        this.sections = document.querySelector('.main').children;
        this.intObserve = new IntersectionObserver(this.onIntersect.bind(this), this.options);
        Array.from(this.sections).forEach(this.pages.bind(this));
        this.updateLogo(this.sections[0]);
    },
}

export default scrollGround;
