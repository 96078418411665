import Swiper from 'swiper/bundle';

const slider = {

    loop(index, slide) {
        // let arrow_prev = $('<span>').addClass('arrow').addClass('prev').appendTo(slider);
        // let arrow_next = $('<span>').addClass('arrow').addClass('next').appendTo(slider);
        new Swiper(slide, {
            slidesPerView: 'auto',
            wrapperClass: 'slider-wrapper',
            slideClass: 'slider-slide',
            grabCursor: true,
            navigation: {
                nextEl: '.slider-next',
                prevEl: '.slider-prev',
            },
        });
    },

    init() {
        let $sectionSlider = $('.section .slider');
        if ($sectionSlider.length) $sectionSlider.each(this.loop);
    },
}

export default slider;
