const $html = $('html');
const header = {

    toggler() {
        let scroll = $(window).scrollTop();
        $html.toggleClass('menu-open');
        if (scroll > 500) {
            this.$header.toggleClass('dark');
        }
    },

    init() {
        this.$header = $('header');
        this.$handler = $('#header-handle');
        this.$handler.on('click', this.toggler.bind(this));
    },
}

export default header;
